import React, { useEffect, useState } from "react"
import { Link } from 'gatsby'
import queryString from 'query-string'
import axios from 'axios'
import { FormattedMessage } from "react-intl"
import { Breadcrumb, Tabs, message } from 'antd'

import Seo from '../../components/seo'
import Layout from "../../components/layout"
import WLoading from "../../components/wLoading"

import '../../style/security.scoped.scss'

const { TabPane } = Tabs;

const AdvisoriesDetail = ({ pageContext, location }) => {
  const id = queryString.parse(location.search).id

  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  const apiPrefix = process.env && process.env.NODE_ENV === 'development' ? 'http://jenkins.mogdb.enmotech.com:7004/api' : '/api'
  const [detailData, setDetailData] = useState({})
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true)
    const res = await axios.get(apiPrefix + '/public/security/' + id);
    setLoading(false)
    if (res && res.data && res.data.code === 200) {
      const result = res.data.data || {}
      setDetailData(result)
    } else {
      message.error(res.data && res.data.message || 'error');
    }
  }, []);

  function getContent (key) {
    return replaceUnmatchedBrackets(detailData[pageLang === 'zh' ? `${key}Zh` : key] || detailData.key)
  }
  function replaceUnmatchedBrackets(str) {  
    if (!str) return str
    const regx = /<(?![^<>]*>)/g;
    return str.replace(regx, '&lt;');
  }

  const TabsDom = () => (
    <Tabs defaultActiveKey="1" className="a-tabs">
      <TabPane tab={<FormattedMessage id="advisoriesDeatil.tab1" />} key="1">
        <div className="summary-content">
          <h4><FormattedMessage id="advisoriesDeatil.title1" /></h4>
          <p className="js-summary">{getContent('description')}</p>
          <h4><FormattedMessage id="advisoriesDeatil.title2" /></h4>
          <p className="js-cveLevel">{detailData.level}</p>
          <h4><FormattedMessage id="advisoriesDeatil.title3" /></h4>
          <p className="js-theme">{getContent('theme')}</p>
          <h4><FormattedMessage id="advisoriesDeatil.title4" /></h4>
          <p className="js-description" dangerouslySetInnerHTML={{ __html: getContent('content') }}></p>
          <h4><FormattedMessage id="advisoriesDeatil.title5" /></h4>
          <p className="js-influence">{detailData.component}</p>
          <h4>CVE</h4>
          <p className="js-cve">
            {detailData.link && detailData.link.map((item, idx) => <Link key={idx} to={`${pagePrefix}/cve/detail?id=${item.id}`} className="link-text">{item.name}</Link>)}
          </p>
          <h4><FormattedMessage id="advisoriesDeatil.title6" /></h4>
          <p className="js-reference">
            {detailData.reference && detailData.reference.map((item, idx) => <a className="link-text" key={idx} target="_blank" href={item}>{item}</a>)}
          </p>
        </div>
      </TabPane>
      <TabPane tab={<FormattedMessage id="advisoriesDeatil.tab2" />} key="2">
        <h3 className="js-pack-title">MogDB-{detailData.version}</h3>
        <div className="connector-pack">
          <h5>MogDB Server</h5>
          <div className="w-li-table w-li-table-pc">
            <ul className="pack-title">
              <li></li>
              <li>centos_x86_64</li>
              <li>openeuler_aarch64</li>
              <li>openeuler_x86_64</li>
            </ul>
            {detailData.products && detailData.products.map((d, dIdx) =>
              <ul className="pack-content" key={dIdx}>
                <li>{d.name}</li>
                <li><div className="oval-box"></div></li>
                <li><div className="oval-box"></div></li>
                <li><div className="oval-box"></div></li>
              </ul>
            )}
          </div>
          <div className="pack-version w-li-table w-li-table-mobile">
            <ul className="pack-title">
              <li></li>
              <li>{detailData.packageName}</li>
            </ul>
            <ul className="pack-content">
              <li>centos_x86_64</li>
              <li><div className="oval-box"></div></li>
            </ul>
            <ul className="pack-content">
              <li>openeuler_aarch64</li>
              <li><div className="oval-box"></div></li>
            </ul>
            <ul className="pack-content">
              <li>openeuler_x86_64</li>
              <li><div className="oval-box"></div></li>
            </ul>
          </div>
        </div>
      </TabPane>
    </Tabs>
  )
  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: Advisories Detail" />
      <div className="w-banner-container">
        <div className="w-banner-box"><FormattedMessage id="secutity.pageTitle" /></div>
      </div>
      <WLoading spinning={loading}>
        <div className="security-container">
          <div className="security-info">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href={`${pagePrefix}/advisory/`}><FormattedMessage id="secutity.tab2" /></a>
              </Breadcrumb.Item>
              <Breadcrumb.Item><FormattedMessage id="secutity.detail" /></Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="title">{detailData.name}</h2>
            <p className="date-info"><span><FormattedMessage id="advisoriesDeatil.releaseDate" />：</span>{detailData.releaseDate}</p>
            <TabsDom></TabsDom>
          </div>
        </div>
      </WLoading>
    </Layout>
  )
}
export default AdvisoriesDetail